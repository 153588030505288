const state = {
  loginSuccess: false,
};

const getters = {
  isLoggedIn(status) {
    return status.loginSuccess;
  },
};

const mutations = {
  login(status, info) {
    status.loginSuccess = info;
  },
  logout(status) {
    status.loginSuccess = false;
  },
};

// const actions = {
//   login ({commit}, {id, password}) {
//       const params = {
//           "userId": id,
//           "password": password
//       }
//       try {
//         const result = this.$axios.post('/auth/authenticate', {
//             userId: this.id,
//             password: this.password
//         });
//         if (result.status === 200) {
//             storage.setToken(result.data);
//             storage.setRefreshToken(result.data);
//             this.$router.push({
//             path: '/applicant/category'
//             });
//         }
//     } catch (err) {
//         window.alert('로그인정보가 올바르지 않습니다');
//     }

//  },
//   logout ({commit}) {
//       commit('logout')
//   }
// };

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
